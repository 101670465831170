<template>
  <div class="content">
    <div class="searchWrapper">
      <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
      <!--（表单）选项区域-->
      <el-form ref="form" :model="formInline" label-width="100px" :inline="true">
        <div class="col_box">
          <div class="col_left">
            <el-form-item label="停车记录ID:">
              <el-input
                v-model="formInline.orderId"
                :onkeyup="(formInline.orderId = formInline.orderId.replace(/[^\d]/g, ''))"
                placeholder="停车记录ID"
                maxlength="19"
                style="width: 200px"
              ></el-input>
            </el-form-item>
            <el-form-item label="区域:">
              <a-cascader ref="cascader"></a-cascader>
            </el-form-item>
            <el-form-item :label="$t('searchModule.Merchant_Name')" prop="operationId">
              <el-select
                style="width: 206px"
                v-model.trim="formInline.operationId"
                filterable
                size="15"
              >
                <el-option label="全部" value=""></el-option>
                <el-option
                  :label="value.operationName"
                  :value="value.operationId"
                  :key="value.operationId"
                  v-for="value in operationList"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('searchModule.Road_or_Parking_Lot')">
              <el-autocomplete
                class="inline-input"
                v-model="modelvalue"
                :fetch-suggestions="querySearchAsync"
                placeholder="请输入内容"
                value-key="parkName"
                :trigger-on-focus="false"
                @select="handleSelect"
              ></el-autocomplete>
            </el-form-item>
            <el-form-item :label="$t('searchModule.Type_of_parking_lot')">
              <el-select v-model.trim="formInline.parkType" filterable size="15">
                <el-option label="全部" value=""></el-option>
                <el-option
                  :label="value.desc"
                  :value="value.code"
                  :key="value.code"
                  v-for="value in parkTypeList"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('searchModule.plate_number')" prop="plateNumber" ref="plateNumber">
              <el-autocomplete
                ref="plateNumber"
                size="11"
                valueKey="plateNumber"
                class="inline-input"
                v-model="formInline.plateNumber"
                :fetch-suggestions="querySearchAsyncCar"
                placeholder="车牌号"
                :maxlength="8"
                :trigger-on-focus="false"
                @select="handleSelectCar"
              ></el-autocomplete>
            </el-form-item>
            <el-form-item :label="$t('searchModule.Entry_and_exit_types')">
              <el-select
                style="width: 206px"
                v-model.trim="formInline.exitState"
                filterable
                size="15"
              >
                <!-- <el-option label="全部" value='0'></el-option> -->
                <el-option
                  :label="value.exitStateName"
                  :value="value.exitStateId"
                  :key="value.exitStateId"
                  v-for="value in exitStateList"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="时间段:">
              <timeRangePick
                @timeChange="timeChange"
                ref="timeRangePicker"
                :defaultRange="defaultRangeValue"
                :defalutDate="defalutDate"
              />
            </el-form-item>
          </div>
          <div class="col_right">
            <el-button
              type="primary"
              icon="el-icon-search"
              @click="
                pageNum = 1;
                searParkRecordList();
              "
              v-if="$route.meta.authority.button.query"
              :loading="loading"
              >{{ $t('button.search') }}</el-button
            >
            <el-button type="info" icon="el-icon-delete" @click="reast">{{ $t('button.reset') }}</el-button>
          </div>
        </div>
      </el-form>
    </div>
    <!--列表-->
    <div class="tableWrapper bgFFF paddingB20">
      <!-- <div class="tableWrapperTitle">
        <div class="tableWrapperTitleLeft">申诉记录列表</div>
        <el-button type="primary" icon="el-icon-search" @click="batchProcessing"
          >批量处理</el-button
        >
      </div> -->
      <el-table
        border
        :data="tableData"
        style="width: 100% !important"
        @selection-change="handleSelectionChange"
      >
        <!-- <el-table-column
          type="selection"
          width="55"
          :selectable="selectaDis"
          align="center"
        >
        </el-table-column> -->

        <el-table-column
          align="center"
          :label="item.label"
          :width="item.width"
          v-for="item in tableCols"
          :key="item.prop"
        >
          <template slot-scope="scope">
            <span
              v-if="item.prop == 'parkRecordId'"
              v-clipboard:copy="scope.row.parkRecordId"
              v-clipboard:success="onCopy"
              class="orderNumberStyle"
            >
              <p>{{ scope.row.parkRecordId }}</p>
            </span>
            <span
              v-else-if="item.prop == 'exitTime'"
              v-clipboard:copy="scope.row.exitTime"
              v-clipboard:success="onCopy"
              class="orderNumberStyle"
            >
              <p>{{ scope.row.exitTime }}</p>
            </span>
            <span v-else>
              <p>
                {{ item.formatter ? item.formatter(scope.row) : scope.row[item.prop] }}
              </p>
            </span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="操作"
          fixed="right"
          width="100px"
          v-if="$route.meta.authority.button.view || $route.meta.authority.button.view"
        >
          <template slot-scope="scope">
            <el-button
              type="text"
              class="but"
              @click="detailShow(scope.row)"
              v-if="$route.meta.authority.button.view"
              >查看</el-button
            >
            <!-- <el-button type="text" v-if="scope.row.appealDealStatus == 0" @click="openTips"
              >查看</el-button
            > -->
            <el-button
              type="text"
              class="but"
              v-if="scope.row.appealDealStatus != 0 && $route.meta.authority.button.view"
              :disabled="scope.row.recordState == 1 && scope.row.dataSource == 2"
              @click="
                batchType = 2;
                getInvoicing(scope.row.parkRecordId, scope.row);
              "
              >处理</el-button
            >
            <span class="hading" v-if="scope.row.appealDealStatus == 0" @click="openTips"
              >处理中</span
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="pagerWrapper">
        <div class="block">
          <el-pagination
            v-if="total != 0"
            @current-change="handleCurrentChange"
            :current-page="pageNum"
            :page-size="pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- <processPicture :dialogVisible="dialogVisible" @consoleException="consoleException"></processPicture> -->
    <processException
      ref="processEc"
      :dialogVisibleException="dialogVisibleException"
      @consoleException="consoleException"
      :tableException="tableDetails"
      :batchType="batchType"
    ></processException>
    <!-- 弹窗 -->
    <processPicDetail
      :infoSize="4"
      :infoDetail="parkDetail"
      :rowData="rowData"
      :detailFlag="dialogVisiblePicDetail"
      :tableOrder="tableOrder"
      :parkRecordIdTable="parkRecordIdTable"
      @close="dialogVisiblePicDetail = false"
      :total="total1"
      :operationTable="operationTable"
      :operationData="operationData"
      :operationHistoryData="operationHistoryData"
      :operationTableHistory="operationTableHistory"
      :tableOrderdata="tableOrderdata"
      :parkRecordIdData="parkRecordIdData"
      @getOrder="clildrenHandleCurrentChange"
      :entryPic="entryPic"
      :exitPic="exitPic"
      :isCorrect="false"
    ></processPicDetail>
  </div>
</template>

<script>
import timeRangePick from "@/components/timePicker";
import processException from "./errorderComponets/processException";
// import processPicDetail from "./errorderComponets/processPicDetail";
import processPicDetail from "@/components/picDetail/picDetail";
import { setIndex, dateFormat } from "@/common/js/public.js";
export default {
  name: "exceptionOrder_start",
  data() {
    return {
      operationList: [],
      parkTypeList: [],
      exitStateList: [
        { exitStateId: 1, exitStateName: "入场" },
        { exitStateId: 2, exitStateName: "出场" },
      ],
      batchSelection: [],
      batchType: -1,
      page: 1,
      size: 15,
      rowData: {},
      operationData: [],
      operationHistoryData: [],
      parkRecordIdData: [],
      tableOrderdata: [],
      total1: 0,
      entryPic: [],
      exitPic: [],
      parkDetail: [],
      parkRecordId: "",
      modelvalue: "",
      dialogVisible: false,
      dialogVisibleException: false,
      dialogVisiblePicDetail: false,
      defalutDate: [],
      pageNum: 1,
      total: 0,
      pageSize: 15,
      loading: false,
      tableData: [],
      refundTypeList: [],
      clickIndex: "",
      indexShow: [],
      formInline: {
        orderId: "",
        areaId: "",
        operationId: "",
        parkId: "",
        parkType: "",
        parkName: "",
        plateNumber: "",
        carId: "",
        exitState: 1,

        input1: "",
        startTime: "",
        endTime: "",
        // refundType: ''   // 待定功能，未联调
      },
      tableOrder: [
        { label: this.$t("list.order_ID"), value: "payOrderId", width: "" },
        { label: this.$t("list.entry_time"), value: "entryTime", width: "" },
        { label: this.$t("list.billable_hours"), value: "exitTime", width: "" },
        { label: this.$t("list.payment_time"), value: "payTime", width: "" },
        {
          label: this.$t("list.order_amount"),
          value: "shouldPay",
          width: "",
          formatter: (row, column) => {
            return row.shouldPay == 0 ? "0元" : row.shouldPay / 100 + "元";
          },
        },
        {
          label: this.$t("list.preferential_amount"),
          value: "agioPay",
          width: "",
          formatter: (row, column) => {
            return row.agioPay == 0 ? "0元" : row.agioPay / 100 + "元";
          },
        },
        {
          label: this.$t("list.Actual_received_amount"),
          value: "money",
          width: "",
          formatter: (row, column) => {
            return row.money == 0 ? "0元" : row.money / 100 + "元";
          },
        },
        { label: this.$t("list.Payment_Type"), value: "paymentTypeDesc", width: "" },
        { label: this.$t("list.Payment_channels"), value: "channelTypeDesc", width: "" },
        { label: this.$t("list.Payment_devices"), value: "devTypeDesc", width: "" },
        { label: this.$t("list.Payer"), value: "operatorName", width: "" },
        { label: "收费道路/车场", value: "payParkName", width: "120" },
        { label: this.$t("list.Third_party_transaction_ID"), value: "tradeNo", width: "120" },
      ],
      parkRecordIdTable: [
        {
          label: this.$t("list.Refund_method"),
          value: "refundModeName",
        },
        // {
        //   label: "支付方式",
        //   value: "payTypeName"
        // },
        {
          label: this.$t("list.Payment_channels"),
          value: "channelTypeDesc",
        },
        {
          label: this.$t("list.Payment_devices"),
          value: "devTypeDesc",
        },
        {
          label: this.$t("list.refund_reason"),
          value: "refundReasonName",
        },
        {
          label: this.$t("list.Actual_appearance_time"),
          value: "actualExitTime",
          formatter: (row) => {
            if (row.actualExitTime) {
              return this.timestampToTime(row.actualExitTime);
            } else {
              return "";
            }
          },
        },
        {
          label: this.$t("list.refund_amount"),
          value: "actualRefundPay",
          formatter: (row, column) => {
            if (row.actualRefundPay) {
              return Number(row.actualRefundPay / 100).toFixed(2);
            } else {
              return "0.00";
            }
          },
        },
        {
          label: this.$t("list.applicant"),
          value: "operatorName",
        },
        {
          label: this.$t("list.Application_Time"),
          value: "createdTime",
        },
        {
          label: this.$t("list.Refund_time"),
          value: "refundTime",
          formatter: (row) => {
            if (row.refundTime) {
              return this.timestampToTime(row.refundTime);
            } else {
              return "";
            }
          },
        },
      ],
      operationTable: [
        {
          label: this.$t("list.Operation_type"),
          value: "typeDesc",
        },
        {
          label: this.$t("list.Operation_source"),
          value: "dataSourceName",
        },
        {
          label: this.$t("list.Operator_or_Equipment"),
          value: "operator",
          formatter: (row) => {
            // if (row.type === 5) {  //
            //   return row.equipmentCode;
            // } else if (row.type === 6) {
            //   return row.equipmentCode;
            // } else {
            //   return row.operator;
            // }
            return row.operator;
          },
        },
        {
          label: this.$t("list.Operation_time"),
          value: "operateTime",
        },
      ],
      operationTableHistory: [
        {
          label: this.$t("list.state"),
          value: "statusName",
        },
        {
          label: "处理记录ID",
          value: "parkOrderAppealId",
          width: "180",
        },
        {
          label: this.$t("list.processing_time"),
          value: "handleTime",
          width: "180",
        },
        {
          label: this.$t("list.Processing_personnel"),
          value: "handleOperatorName",
        },
        {
          label: "业务订单类型",
          value: "businessTypeName",
          width: "120",
        },
        {
          label: "更改项",
          value: "appealTypeName",
          width: "120",
        },
        {
          label: "更改初始值",
          value: "originValue",
          width: "120",
        },
        {
          label: "更改更新值",
          value: "currentValue",
          width: "120",
        },
      ],
      tableCols: [
        {
          prop: "index",
          label: "序号",
          width: "70",
        },
        {
          prop: "parkRecordId",
          label: this.$t("list.Parking_record_ID"),
          width: "180",
        },
        {
          prop: "parkTime",
          label: this.$t("list.Parking_duration"),
          width: "120",
        },
        {
          prop: "areaName",
          label: this.$t("list.region"),
          width: "",
        },
        {
          prop: "operationName",
          label: this.$t("list.Belonging_operator"),
          width: "120",
        },
        {
          prop: "parkName",
          label: "道路/车场",
          width: "180",
        },
        {
          prop: "parkTypeName",
          label: this.$t("list.Type_of_parking_lot"),
          width: "120",
        },
        {
          prop: "plateNumber",
          label: this.$t("list.plate_number"),
          width: "120",
        },
        {
          prop: "berthCode",
          label: this.$t("list.Berth_number"),
          width: "120",
        },
        {
          prop: "strEntryTime",
          label: this.$t("list.entry_time"),
          width: "180",
        },
        {
          prop: "strExitTime",
          label: this.$t("list.Appearance_time"),
          width: "180",
        },
        {
          prop: "shouldPay",
          label: "应收金额(元)",
          width: "120",
          formatter: (row, column) => {
            return row.shouldPay ? row.shouldPay / 100 + "元" : " 0.00元";
          },
        },
        {
          prop: "lackMoney",
          label: "欠费总计(元)",
          width: "120",
          formatter: (row, column) => {
            return row.lackMoney ? row.lackMoney / 100 + "元" : " 0.00元";
          },
        },
      ],
      tableDetails: [
        {
          prop: "parkRecordId",
          label: this.$t("list.Parking_Order_ID"),
          width: "180",
        },
        {
          prop: "plateNumber",
          label: this.$t("list.plate_number"),
          width: "150",
        },
        {
          prop: "parkName",
          label: "道路/车场",
          width: "",
        },
        {
          prop: "strEntryTime",
          label: this.$t("list.entry_time"),
          width: "180",
        },
        {
          prop: "strExitTime",
          label: this.$t("list.Appearance_time"),
          width: "180",
        },
        // {
        //   prop: "shouldPay",
        //   label: "应收金额(元)",
        //   width: "120",
        //   formatter: (row, column) => {
        //     if (row.shouldPay) {
        //       console.log("row.shouldPay", row.shouldPay);
        //       if (row.shouldPay >= 0) {
        //         return Number(row.shouldPay / 100).toFixed(2);
        //       } else {
        //         return row.shouldPay;
        //       }
        //     } else {
        //       return "0.00";
        //     }
        //   },
        // },
        // {
        //   prop: "lackMoney",
        //   label: "欠费金额(元)",
        //   width: "120",
        //   formatter: (row, column) => {
        //     if (row.lackMoney) {
        //       return Number(row.lackMoney / 100).toFixed(2);
        //     } else {
        //       return "0.00";
        //     }
        //   },
        // },
      ],
    };
  },
  watch: {},
  computed: {
    defaultRangeValue() {
      return [{ typeNumber: this.formInline.plateNumber, warntip: "请输入您查询的车牌号" }];
    },
  },
  methods: {
    openTips() {
      this.$alert("当前记录已存在等待处理的申诉记录, 请前往处理或修改处理方案", this.$t('pop_up.Tips'), {
        confirmButtonText: this.$t('pop_up.Determine'),
      });
    },
    timestampToTime(timestamp) {
      var date = new Date(timestamp * 1); // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() + "-";
      var M = (date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1) + "-";
      var D = date.getDate() < 10 ? "0" + date.getDate() + " " : date.getDate() + " ";
      var h = date.getHours() < 10 ? "0" + date.getHours() + ":" : date.getHours() + ":";
      var m = date.getMinutes() < 10 ? "0" + date.getMinutes() + ":" : date.getMinutes() + ":";
      var s = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      return Y + M + D + h + m + s;
    },
    reast() {
      this.formInline.orderId = "";
      this.formInline.areaId = "";
      this.formInline.operationId = "";
      this.formInline.parkId = "";
      this.modelvalue = "";
      this.formInline.parkType = "";
      this.formInline.parkName = "";
      this.formInline.plateNumber = "";
      this.formInline.carId = "";
      this.formInline.exitState = 1;
      this.$refs.cascader.clear();
      this.$refs.timeRangePicker.resetTime();
    },
    /* 获取开票状态查询 */
    getInvoicing(datas, e) {
      console.log("datas11", datas, e);
      // this.clickIndex = e.index;
      if (e) {
        this.indexShow = [];
        this.indexShow.push(e.index);
      }
      let url = "/acb/2.0/orderAppeal/checkInvoiceStatus";
      this.$axios
        .get(url, {
          data: {
            parkRecordIds: datas,
            indexNos: this.indexShow.join(","),
          },
        })
        .then((res) => {
          // 1 谭提示 0 不弹提示直接谈详情
          if (res.value.status == 0) {
            this.seeDetails(datas);
          } else {
            this.$confirm(res.value.desc + "是否继续?", this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
              cancelButtonText: "取消",
              type: "warning",
            })
              .then(() => {
                this.seeDetails(datas);
              })
              .catch(() => {});
          }
        });
    },
    /* 获取车场类型 */
    getPark() {
      let url = "/acb/2.0/park/spinner";
      this.$axios
        .get(url, {
          data: {},
        })
        .then((res) => {
          if (res.state == 0) {
            this.parkTypeList = res.value.parkType;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    // 批量处理
    batchProcessing() {
      console.log("this.batchSelection", this.batchSelection);
      if (this.batchSelection.length == 0) {
        this.$alert("请选择要批量处理的数据", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return;
      }
      let batchSel = "";
      for (let item of this.batchSelection) {
        batchSel += item.parkRecordId + ",";
      }
      this.batchType = 1;
      this.getInvoicing(batchSel.substring(0, batchSel.length - 1));
    },
    handleSelectionChange(val) {
      console.log("val", val);
      this.indexShow = [];
      for (var i = 0; i < val.length; i++) {
        this.indexShow.push(val[i].index);
      }
      console.log(this.indexShow, "indexShow");
      this.batchSelection = val;
    },
    selectaDis(row, index) {
      if (row.appealDealStatus == 0) {
        return false;
      } else {
        return true;
      }
    },
    clildrenHandleCurrentChange(val) {
      this.getOrder(val);
    },
    detailShow(data) {
      console.log("查看detailShow-->", data);
      this.parkRecordId = data.parkRecordId;

      this.rowData = Object.assign({}, data);
      this.dialogVisiblePicDetail = true;
      // 历史处理
      this.orderAppeal();
      // 停车场详情
      this.getRsType(data);
      // 支付记录
      this.getOrder();
      // 退款记录
      this.refundOrder();
      //    this.getRefund();
      // 操作记录
      this.getOperation();
    },
    orderAppeal() {
      let opt = {
        url: "/acb/2.0/orderAppeal/getByParkRecordId",
        method: "get",
        data: {
          parkRecordId: this.parkRecordId,
        },
        success: (res) => {
          if (res.state == 0) {
            this.operationHistoryData = res.value;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        },
      };
      this.$request(opt);
    },
    getOperation() {
      let opt = {
        url: "/acb/2.0/parkRecord/getOcrReocrdByParkRecordId/" + this.rowData.parkRecordId,
        method: "get",
        data: {
          // page: 1,
          // pageSize: this.pageSize,
          // parkRecordId: this.rowData.parkRecordId
        },
        success: (res) => {
          if (res.state == 0) {
            this.operationData = res.value;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        },
      };
      this.$request(opt);
    },
    refundOrder() {
      this.$axios
        .get("/acb/2.0/refundRecord/list", {
          data: {
            page: this.page,
            size: this.size,
            parkRecordId: this.parkRecordId,
            refundState: 5,
          },
        })
        .then((res) => {
          this.parkRecordIdData = res.value.list;
        });
    },
    getOrder(pageNum) {
      let page = pageNum || 1;
      let opt = {
        url: "/acb/2.0/payOrder/payOrderByParkRecordId/" + this.rowData.parkRecordId,
        method: "get",
        data: {
          page: page,
          pageSize: this.pageSize,
          parkRecordId: this.rowData.parkRecordId,
        },
        success: (res) => {
          if (res.state == 0) {
            this.tableOrderdata = res.value;
            this.total1 = res.value.total * 1 || 0;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        },
      };
      this.$request(opt);
    },
    getRsType({ parkRecordId }) {
      let opt = {
        url: "/acb/2.0/parkRecord/" + parkRecordId,
        method: "get",
        // data: this.formInline,
        success: (res) => {
          if (res.state == 0) {
            this.parkDetail = res.value;
            this.getPic(res.value.entryOprNum, res.value.exitOprNum);
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        },
      };
      this.$request(opt);
    },
    // 获取停车场详情图片
    getPic(entryOprNum, exitOprNum) {
      this.entryPic = [];
      this.exitPic = [];
      if (entryOprNum) {
        let opt = {
          method: "get",
          url: "/acb/2.0/parkRecord/picture/" + entryOprNum,
          success: (res) => {
            if (res.state == 0) {
              this.entryPic = res.value;
            } else {
              this.$alert(res.desc, this.$t('pop_up.Tips'), {
                confirmButtonText: this.$t('pop_up.Determine'),
              });
            }
          },
        };
        this.$request(opt);
      }
      if (exitOprNum) {
        let opt = {
          method: "get",
          url: "/acb/2.0/parkRecord/picture/" + exitOprNum,
          success: (res) => {
            if (res.state == 0) {
              this.exitPic = res.value;
            } else {
              this.$alert(res.desc, this.$t('pop_up.Tips'), {
                confirmButtonText: this.$t('pop_up.Determine'),
              });
            }
          },
        };
        this.$request(opt);
      }
    },
    // handleAreaChange() {
    //   let areaId = "";
    //   if (this.$refs.cascader) {
    //     areaId = this.$refs.cascader.getStreetId()
    //       ? this.$refs.cascader.getStreetId()
    //       : this.$refs.cascader.getAreaId();
    //   }
    //   this.formInline.areaId = areaId;
    // },
    handleSelect(item) {
      this.formInline.parkId = item.parkId;
    },
    handleSelectCar(item) {
      this.loading = false;
      this.formInline.plateNumber = item.plateNumber;
      this.formInline.carId = item.carId;
      //   this.pageNum = 1;
      //   this.searParkRecordList();
    },
    querySearchAsync(queryString, cb) {
      this.$emit("valueChange", "");
      queryString = queryString.replace(/\s/g, "");
      let url = "/acb/2.0/systems/loginUser/getParkName";
      let areaId = "";
      if (this.$refs.cascader) {
        areaId = this.$refs.cascader.getStreetId()
          ? this.$refs.cascader.getStreetId()
          : this.$refs.cascader.getAreaId();
      }
      this.$axios
        .get(url, {
          data: {
            page: 1,
            pageSize: 15,
            parkName: queryString,
            slaveRelations: "0,1,2",
            dataSource: "",
            areaIds: areaId,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            // this.temParkData = res.value.list.length ? res.value.list[0] : { parkName: "", parkId: "" };
            this.formInline.parkId = "";
            // this.parkData = res.value.list;
            cb(res.value.list);
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    querySearchAsyncCar(queryString, cb) {
      this.loading = false;
      if (queryString.length < 3) {
        cb([]);
        return;
      }
      let opt = {
        method: "get",
        url: "/acb/2.0/parkRecord/plateNumber/list",
        data: {
          part: queryString,
          size: 100,
        },
        success: (res) => {
          if (res.state == 0) {
            res.value.forEach((v) => {
              v.value = v.plateNumber;
            });
            cb(res.value);
          }
        },
      };
      this.$request(opt);
    },
    /* 商户名称的下拉框 */
    getTenantList() {
      this.$axios.get("/acb/2.0/operation/nameList").then((res) => {
        if (res.state == 0) {
          this.operationList = res.value;
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    consoleException() {
      this.dialogVisibleException = false;
      this.searParkRecordList();
    },
    dataTimeRest() {
      let nowDate = new Date();
      let year = nowDate.getFullYear();
      let month =
        nowDate.getMonth() + 1 < 10 ? "0" + (nowDate.getMonth() + 1) : nowDate.getMonth() + 1;
      let day = nowDate.getDate() < 10 ? "0" + nowDate.getDate() : nowDate.getDate();
      let dateStr = year + "-" + month + "-" + day;
      return dateStr;
    },
    timeChange(timeArr, shortcutKey) {
      this.formInline.startTime = timeArr[0];
      this.formInline.endTime = timeArr[1];
      if (shortcutKey) {
        this.searParkRecordList();
      }
    },
    // 分页
    handleCurrentChange(val) {
      this.pageNum = val;
      this.searParkRecordList();
    },
    seeDetails(data) {
      this.dialogVisibleException = true;
      this.$refs.processEc.refundRecordIdFn(data);
      // this.$refs.processEc.processExceptionRecords(data);
    },

    // 搜索
    searParkRecordList() {
      // this.searParkRecordListFun();
      let flag = this.showLog();
      if (flag) {
        this.flag = false;
        this.searParkRecordListFun();
      }
    },
    showLog() {
      if (this.formInline.startTime && this.formInline.endTime) {
        let time = new Date(this.formInline.endTime) - new Date(this.formInline.startTime);
        time = time / (24 * 60 * 60 * 1000);
        if (!this.formInline.carId && time >= 31) {
          this.$alert("查询时间不能大于31天");
          return false;
        } else {
          return true;
        }
      } else if (this.formInline.orderId) {
        return true;
      } else {
        this.$alert("日期不能为空", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return false;
      }
    },
    onCopy() {
      this.$message({
        message: "已复制",
        type: "success",
      });
    },
    searParkRecordListFun() {
      console.log(this.formInline, "formInline.plateNumber");
      if (this.modelvalue == "") {
        this.formInline.parkId = "";
      }
      if (this.formInline.plateNumber == "") {
        this.formInline.carId = "";
      }
      let areaId = "";
      if (this.$refs.cascader) {
        areaId = this.$refs.cascader.getStreetId()
          ? this.$refs.cascader.getStreetId()
          : this.$refs.cascader.getAreaId();
      }
      console.log("this.fomeline", this.fomeline);
      let opt = {
        method: "get",
        url: "/acb/2.0/parkRecord/orderAppealList",
        data: {
          page: this.pageNum,
          pageSize: this.pageSize,
          parkRecordIds: this.formInline.orderId,
          areaId,
          operationId: this.formInline.operationId,
          parkId: this.formInline.parkId,
          parkType: this.formInline.parkType,
          carId: this.formInline.carId,
          entryOrExit: this.formInline.exitState,
          // startTime: "2021-01-01 00:00:00",
          startTime: this.formInline.startTime,
          endTime: this.formInline.endTime,
          exception: 0,
        },
        success: (res) => {
          this.loading = false;
          if (res.state == 0) {
            this.tableData = setIndex(this.pageNum, res.value.list);
            this.total = res.value.total * 1 || 0;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        },
      };
      this.$request(opt);
    },
    get7Day(data) {
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * data);
      return dateFormat(start, "yyyy-MM-dd") + " 00:00:00";
    },
  },
  created() {
    this.getTenantList();
    this.getPark();
    this.defalutDate = [this.get7Day(7), this.dataTimeRest() + " 23:59:59"];
    // this.searParkRecordList();
    this.formInline.startTime = this.defalutDate[0];
    this.formInline.endTime = this.defalutDate[1];
    console.log("route", this.$route);
    if (this.$route.params.parkRecordIds) {
      this.formInline.orderId = this.$route.params.parkRecordIds;
    }
  },
  mounted() {
    this.searParkRecordList();
  },
  components: {
    timeRangePick,
    processException,
    processPicDetail,
  },
};
</script>
<style scoped lang="stylus" rel="stylesheet/stylus">
.tableWrapperTitle {
  display: flex;
  justify-content: space-between;
  background: #fff;
}

.tableWrapperTitleLeft {
  width: 100px;
  height: 40px;
  line-height: 40px;
  padding-left:10px;
}

.pagerWrapper {
  text-align: right;
  margin-top: 28px;
  font-size: 12px;
}

.el-form {
  padding-top: 23px;
}

.optionAreaOne {
  display: flex;
}

.optionAreaThree {
  display: flex;
}

.optionAreaThree2 {
  display: flex;
  justify-content: right;
}

.title {
  margin-top: 30px;
}

.warning {
  color: red;
  width: 100%;
  text-align: center;
  margin-top: 10px;
}

.titleh1 {
  margin: 10px 10px;
  font-size: 15px;
}

.but {
  padding: 5px;
}

.orderNumberStyle {
  color: #0f6eff;
}

.hading {
  margin-left: 10px;
  text-decoration: underline;
  cursor: pointer;
}
</style>
